import React, { useContext, useState } from "react";
import { FaX } from "react-icons/fa6";
import Popup from "reactjs-popup";
import { ColorContext } from "App";
import microsoftlogo from "assets/microsoftlogo.png";
import { IoIosRefresh } from "react-icons/io";
import * as api from "../../../apis/FusionAPI";
import { sizing } from "@mui/system";

export default function ImportMSRoles({
  list,
  msGroups,
  linkMulti,
  setLinkMulti,
  setShowImportRoles,
  setShowConfirmLinkRoles,
  newRoles,
  setNewRoles,
}) {
  const theme = useContext(ColorContext);
  const [searchFilter, setSearchFilter] = useState("");

  return (
    <div>
      <div>
        <input
          type="text"
          placeholder="Search"
          style={{
            boxSizing: "border-box",
            width: "100%",
            padding: 10,
            marginBottom: 10,
            borderRadius: 5,
            border: `1px solid gray`,
          }}
          onChange={(e) => setSearchFilter(e.target.value)}
        />
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          alignContent: "center",
          alignItems: "center",
          gap: 10,
          marginBottom: 10,
        }}
      >
        <div>Microsoft Security Group:</div>
        <div>Fusion Roles:</div>
      </div>
      <div
        style={{
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          gap: 10,
        }}
      >
        <div
          className="customScrollbar-Y"
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "400px",
            overflow: "auto",
          }}
        >
          {msGroups
            .sort((a, b) => {
              return a.displayName.localeCompare(b.displayName);
            })
            .filter((group) => {
              return (
                group.displayName
                  .toLowerCase()
                  .includes(searchFilter.toLowerCase()) ||
                (linkMulti[group.id] &&
                  linkMulti[group.id]
                    .map((role) => {
                      return list.find((r) => r.role_Id === role)?.role_name;
                    })
                    .join(", ")
                    .toLowerCase()
                    .includes(searchFilter.toLowerCase()))
              );
            })
            .map((group, i) => {
              return (
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 0.5fr 1fr",
                    background: i % 2 === 0 ? "" : "#2c373b",
                    padding: 10,
                    alignContent: "center",
                  }}
                  key={i}
                >
                  {group.displayName}
                  {!list.map((i) => i.role_name).includes(group.displayName) ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 10,
                        paddingRight: 10,
                      }}
                    >
                      Create New Role?
                      <input
                        type="checkbox"
                        value={
                          newRoles.filter((f) => f.name === group.displayName)
                            .length > 0
                        }
                        onChange={(e) => {
                          let newArr = [...newRoles];
                          if (e.target.checked)
                            newArr.push({
                              name: group.displayName,
                              id: group.id,
                            });
                          else
                            newArr.splice(
                              newArr.findIndex(
                                (f) => f.name === group.displayName
                              ),
                              1
                            );

                          setNewRoles(newArr);
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        color: "grey",
                      }}
                    >
                      {/* Role already exists */}
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      gap: 5,
                    }}
                  >
                    {newRoles.filter((f) => f.name === group.displayName)
                      .length > 0 && (
                      <div
                        style={{
                          width: "calc(100% - 20px)",
                          height: 31,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          backgroundColor: theme.primary,
                          fontSize: 13,
                          borderRadius: 10,
                          gap: 20,
                          border: `1px solid grey`,
                          color: theme.fontColor, // Added to ensure text is visible against the background
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {group.displayName}
                        </div>
                      </div>
                    )}
                    {newRoles.filter((f) => f.name === group.displayName)
                      .length === 0 && (
                      <Popup
                        trigger={
                          <div
                            style={{
                              width: "calc(100% - 20px)",
                              height: 31,
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              paddingLeft: 10,
                              paddingRight: 10,
                              backgroundColor: theme.primary,
                              fontSize: 13,
                              borderRadius: 10,
                              gap: 20,
                              border: `1px solid grey`,
                              color: theme.fontColor, // Added to ensure text is visible against the background
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {(() => {
                                if (!linkMulti[group.id]) return "";
                                const rolesString = linkMulti[group.id]
                                  .map((role) => {
                                    return list.find((r) => r.role_Id === role)
                                      ?.role_name;
                                  })
                                  .join(", ");
                                return rolesString;
                              })()}
                            </div>
                          </div>
                        }
                        position="bottom center"
                        on="hover"
                        closeOnDocumentClick={false}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                            padding: 10,
                            backgroundColor: theme.primary,
                            backgroundColor: "#436C9A",
                            color: theme.fontColor,
                            borderRadius: 10,
                            cursor: "pointer",
                            width: "22.75vw",
                            marginLeft: 6,
                            // gap: 5
                          }}
                        >
                          {list &&
                            list
                              .filter((role) => {
                                return role.role_Id >= 0;
                              })
                              .map((item, index) => {
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      background: linkMulti[group.id]?.includes(
                                        item.role_Id
                                      )
                                        ? theme.primary
                                        : "",
                                      padding: 5,
                                      // borderRadius: 5,
                                    }}
                                    onClick={() => {
                                      setLinkMulti({
                                        ...linkMulti,
                                        [group.id]: linkMulti[
                                          group.id
                                        ]?.includes(item.role_Id)
                                          ? linkMulti[group.id].filter(
                                              (r) => r !== item.role_Id
                                            )
                                          : [
                                              ...(linkMulti[group.id] || []),
                                              item.role_Id,
                                            ],
                                      });
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      checked={linkMulti[group.id]?.includes(
                                        item.role_Id
                                      )}
                                      readOnly
                                    />{" "}
                                    {item.role_name}
                                  </div>
                                );
                              })}
                        </div>
                      </Popup>
                    )}
                    <div
                      onClick={() => {
                        setLinkMulti({
                          ...linkMulti,
                          [group.id]: [],
                        });
                      }}
                      style={{
                        alignContent: "center",
                      }}
                    >
                      <FaX />
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {/* <Popup
          trigger={
            <button
              style={{
                textAlign: "center",
                padding: 15,
                marginTop: 10,
                marginRight: 10,
              }}
              onClick={() => syncRoles()}
            >
              <IoIosRefresh />
            </button>
          }
          position={"top center"}
          on={"hover"}
        >
          <div style={{ padding: 2, backgroundColor: "grey", borderRadius: 5 }}>
            Sync security group users to roles.
          </div>
        </Popup> */}
        <button
          onClick={() => {
            setShowConfirmLinkRoles(true);
          }}
          style={{
            backgroundColor: theme.primary,
            color: theme.fontColor,
            borderRadius: 5,
            width: 100,
            height: 50,
            marginTop: 10,
          }}
        >
          Sync
        </button>
      </div>
    </div>
  );
}
