import React, { useContext } from "react";
import { IconContext } from "react-icons";
import { FaCopy } from "react-icons/fa";
import Popup from "reactjs-popup";
import RoleInput from "./RoleInput";
import { ColorContext } from "App";
import { useNavigate } from "react-router-dom";

const UserEditMenu = ({
  roleList,
  formData,
  setFormData,
  checks,
  perms,
  onSubmit,
  onDiscard,
  apiResonse,
  modalOpen,
  setModalOpen,
  setCopiedText,
  copiedText,
  mode,
  lockedRolesList,
  editable = true,
}) => {
  const theme = useContext(ColorContext);
  const navigate = useNavigate();
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          color: theme.fontColor,
          display: "flex",
          flexDirection: "row",
          margin: 10,
          marginLeft: 0,
          marginTop: 0,

          justifyContent: "space-between",
          width: "100%",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "calc(100% - 20px)",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: 20,
            }}
          >
            <div
              style={{
                width: "100%",
              }}
            >
              <div
                style={{
                  fontSize: 19,
                  fontWeight: "bold",
                }}
              >
                Job Description
              </div>
              <div style={{ fontSize: 13 }}>
                {roleList.filter((item) => {
                  return item.role_Id > 0;
                }).length > 0 ? (
                  <div
                    style={{
                      height: 49,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        color: checks.roles ? "white" : "red",
                      }}
                    >
                      <div>Roles</div>
                      {/* {!checks.roles && <div>* Required Field</div>} */}
                    </div>
                    <RoleInput
                      roleList={roleList}
                      formData={formData}
                      setFormData={setFormData}
                      lockedRolesList={lockedRolesList}
                      editable={editable}
                    />
                  </div>
                ) : (
                  <div
                  // style={{
                  //   height: 49,
                  // }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        color: checks.role ? "white" : "red",
                      }}
                    >
                      <div>
                        <div
                          style={{
                            marginBottom: 5,
                          }}
                        >
                          Roles
                        </div>
                        No Roles have been created yet,{" "}
                        <a
                          onClick={() => {
                            navigate("/system/createrole");
                          }}
                          style={{
                            cursor: "pointer",
                            color: "lightblue",
                            textDecoration: "underline",
                          }}
                        >
                          Click here to Create a Role
                        </a>
                      </div>
                    </div>

                    {/* <#444nput
                      roleList={roleList}
                      formData={formData}
                      setFormData={setFormData}
                    /> */}
                  </div>
                )}
                <div
                  style={{
                    height: 49,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      color: checks.base ? "white" : "red",
                    }}
                  >
                    <div>Base</div>
                    {!checks.base && <div style={{}}>* Required Field</div>}
                  </div>
                  <input
                    disabled={!editable}
                    style={{
                      height: 31,
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      margin: 10,
                      marginLeft: 0,
                      marginTop: 0,
                      backgroundColor: editable ? theme.primary : "#444",
                      fontSize: 13,
                      borderRadius: 10,
                      border: `1px solid grey`,
                    }}
                    onChange={(value) =>
                      setFormData({ ...formData, base: value.target.value })
                    }
                    value={formData.base}
                  />
                </div>

                <div
                  style={{
                    height: 49,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      color: checks.department ? "white" : "red",
                    }}
                  >
                    <div>Department</div>
                    {!checks.department && (
                      <div style={{}}>* Required Field</div>
                    )}
                  </div>
                  <input
                    disabled={!editable}
                    style={{
                      height: 31,
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      margin: 10,
                      marginLeft: 0,
                      marginTop: 0,
                      backgroundColor: editable ? theme.primary : "#444",
                      fontSize: 13,
                      borderRadius: 10,
                      border: `1px solid grey`,
                    }}
                    onChange={(value) =>
                      setFormData({
                        ...formData,
                        department: value.target.value,
                      })
                    }
                    value={formData.department}
                  />
                </div>
                <div
                  style={{
                    height: 49,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      color: checks.rank ? "white" : "red",
                    }}
                  >
                    <div>Rank</div>
                    {!checks.rank && <div style={{}}>* Required Field</div>}
                  </div>
                  <input
                    disabled={!editable}
                    style={{
                      height: 31,
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      margin: 10,
                      marginLeft: 0,
                      marginTop: 0,
                      backgroundColor: editable ? theme.primary : "#444",
                      fontSize: 13,
                      borderRadius: 10,
                      border: `1px solid grey`,
                    }}
                    onChange={(value) =>
                      setFormData({ ...formData, rank: value.target.value })
                    }
                    value={formData.rank}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                width: "100%",
              }}
            >
              <div
                style={{
                  fontSize: 19,
                  fontWeight: "bold",
                }}
              >
                Information
              </div>
              <div style={{ fontSize: 13 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",

                    width: "100%",
                    gap: 20,
                    height: 52,
                  }}
                >
                  <div
                    style={{
                      // width: 251,
                      width: "100%",
                      height: 49,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        color: checks.name ? "white" : "red",
                      }}
                    >
                      <div>Name</div>
                      {!checks.name && <div style={{}}>* Required Field</div>}
                    </div>
                    <input
                      disabled={!editable}
                      style={{
                        // width: 251,
                        width: "100%",
                        height: 31,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        margin: 10,
                        marginLeft: 0,
                        marginTop: 0,

                        backgroundColor: editable ? theme.primary : "#444",
                        fontSize: 13,
                        borderRadius: 10,
                        border: `1px solid grey`,
                      }}
                      onChange={(value) =>
                        setFormData({ ...formData, name: value.target.value })
                      }
                      value={formData.name}
                    />
                  </div>

                  <div
                    style={{
                      // width: 251,
                      width: "100%",
                      height: 49,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        color: checks.email ? "white" : "red",
                      }}
                    >
                      <div>Email</div>
                      {!checks.email && <div style={{}}>* Required Field</div>}
                    </div>
                    <input
                      disabled={mode !== "create"}
                      style={{
                        // width: 251,
                        width: "100%",
                        height: 31,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        margin: 10,
                        marginLeft: 0,
                        marginTop: 0,

                        backgroundColor:
                          mode === "create" ? theme.primary : "#444",
                        fontSize: 13,
                        borderRadius: 10,
                        border: `1px solid grey`,
                      }}
                      onChange={(value) =>
                        setFormData({ ...formData, email: value.target.value })
                      }
                      value={formData.email}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",

                    width: "100%",
                    gap: 20,
                    height: 52,
                  }}
                >
                  <div
                    style={{
                      // width: 251,
                      height: 49,
                      width: "100%",
                    }}
                  >
                    <div>Employee ID</div>
                    <input
                      disabled={!editable}
                      style={{
                        // width: 251,
                        width: "100%",
                        height: 31,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        margin: 10,
                        marginLeft: 0,
                        marginTop: 0,

                        backgroundColor: editable ? theme.primary : "#444",
                        fontSize: 13,
                        borderRadius: 10,
                        border: `1px solid grey`,
                      }}
                      onChange={(value) =>
                        setFormData({
                          ...formData,
                          employeeId: value.target.value,
                        })
                      }
                      value={formData.employeeId}
                    />
                  </div>

                  <div
                    style={{
                      // width: 251,
                      height: 49,
                      width: "100%",
                    }}
                  >
                    <div>Phone Number</div>
                    <input
                      disabled={!editable}
                      style={{
                        // width: 251,
                        width: "100%",
                        height: 31,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        margin: 10,
                        marginLeft: 0,
                        marginTop: 0,

                        backgroundColor: editable ? theme.primary : "#444",
                        fontSize: 13,
                        borderRadius: 10,
                        border: `1px solid grey`,
                      }}
                      onChange={(value) =>
                        setFormData({
                          ...formData,
                          phoneNumber: value.target.value,
                        })
                      }
                      value={formData.phoneNumber}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    // width: "100%",
                    // background:'yellow',
                    // gap: 20,

                    width: "100%",
                    gap: 20,
                    height: 52,
                  }}
                >
                  <div
                    style={{
                      // width: 251,
                      width: "100%",
                      height: 49,
                    }}
                  >
                    <div>Gender</div>
                    <input
                      disabled={!editable}
                      style={{
                        // width: 251,
                        height: 31,
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        margin: 10,
                        marginLeft: 0,
                        marginTop: 0,
                        // paddingLeft: 10,
                        // paddingRight: 10,
                        backgroundColor: editable ? theme.primary : "#444",
                        fontSize: 13,
                        borderRadius: 10,
                        border: `1px solid grey`,
                      }}
                      onChange={(value) =>
                        setFormData({ ...formData, gender: value.target.value })
                      }
                      value={formData.gender}
                    />
                  </div>

                  <div
                    style={{
                      width: "100%",
                      height: 49,
                    }}
                  >
                    <div>Birthday</div>
                    <input
                      disabled={!editable}
                      type="date"
                      style={{
                        // width: 251,
                        width: "100%",
                        height: 31,
                        display: "flex",
                        // paddingLeft: 10,
                        // paddingRight: 10,
                        margin: 10,
                        marginLeft: 0,
                        marginTop: 0,
                        backgroundColor: editable ? theme.primary : "#444",
                        fontSize: 13,
                        borderRadius: 10,
                        border: `1px solid grey`,
                      }}
                      onChange={(value) =>
                        setFormData({
                          ...formData,
                          birthday: value.target.value,
                        })
                      }
                      value={formData.birthday}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",

                    width: "100%",
                    gap: 20,
                    height: 52,
                  }}
                >
                  <div
                    style={{
                      // width: 251,
                      width: "100%",
                      height: 49,
                    }}
                  >
                    <div>Home Address</div>
                    <input
                      disabled={!editable}
                      style={{
                        // width: 251,
                        width: "100%",
                        height: 31,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        margin: 10,
                        marginLeft: 0,
                        marginTop: 0,

                        backgroundColor: editable ? theme.primary : "#444",
                        fontSize: 13,
                        borderRadius: 10,
                        border: `1px solid grey`,
                      }}
                      onChange={(value) =>
                        setFormData({
                          ...formData,
                          address: {
                            ...formData.address,
                            streetAddress: value.target.value,
                          },
                        })
                      }
                      value={formData.address.streetAddress}
                    />
                  </div>

                  <div
                    style={{
                      // width: 251,
                      width: "100%",
                      height: 49,
                    }}
                  >
                    <div>City / County</div>
                    <input
                      disabled={!editable}
                      style={{
                        // width: 251,
                        width: "100%",
                        height: 31,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        margin: 10,
                        marginLeft: 0,
                        marginTop: 0,

                        backgroundColor: editable ? theme.primary : "#444",
                        fontSize: 13,
                        borderRadius: 10,
                        border: `1px solid grey`,
                      }}
                      onChange={(value) =>
                        setFormData({
                          ...formData,
                          address: {
                            ...formData.address,
                            city: value.target.value,
                          },
                        })
                      }
                      value={formData.address.city}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",

                    width: "100%",
                    gap: 20,
                    height: 52,
                  }}
                >
                  <div
                    style={{
                      // width: 251,
                      height: 49,
                      width: "100%",
                    }}
                  >
                    <div>State</div>
                    <input
                      disabled={!editable}
                      style={{
                        // width: 251,
                        width: "100%",
                        height: 31,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        margin: 10,
                        marginLeft: 0,
                        marginTop: 0,

                        backgroundColor: editable ? theme.primary : "#444",
                        fontSize: 13,
                        borderRadius: 10,
                        border: `1px solid grey`,
                      }}
                      onChange={(value) =>
                        setFormData({
                          ...formData,
                          address: {
                            ...formData.address,
                            state: value.target.value,
                          },
                        })
                      }
                      value={formData.address.state}
                    />
                  </div>

                  <div
                    style={{
                      // width: 251,
                      height: 49,
                      width: "100%",
                    }}
                  >
                    <div>Zipcode</div>
                    <input
                      disabled={!editable}
                      style={{
                        // width: 251,
                        width: "100%",
                        height: 31,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        margin: 10,
                        marginLeft: 0,
                        marginTop: 0,

                        backgroundColor: editable ? theme.primary : "#444",
                        fontSize: 13,
                        borderRadius: 10,
                        border: `1px solid grey`,
                      }}
                      onChange={(value) =>
                        setFormData({
                          ...formData,
                          address: {
                            ...formData.address,
                            zipcode: value.target.value,
                          },
                        })
                      }
                      value={formData.address.zipcode}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",

                    width: "100%",
                    gap: 20,
                    height: 52,
                  }}
                >
                  <div
                    style={{
                      // width: 251,
                      height: 49,
                      width: "100%",
                    }}
                  >
                    <div>Emergency Contact Name</div>
                    <input
                      disabled={!editable}
                      style={{
                        // width: 251,
                        width: "100%",
                        height: 31,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        margin: 10,
                        marginLeft: 0,
                        marginTop: 0,

                        backgroundColor: editable ? theme.primary : "#444",
                        fontSize: 13,
                        borderRadius: 10,
                        border: `1px solid grey`,
                      }}
                      onChange={(value) =>
                        setFormData({
                          ...formData,
                          emergencyContact: {
                            ...formData.emergencyContact,
                            name: value.target.value,
                          },
                        })
                      }
                      value={formData.emergencyContact.name}
                    />
                  </div>

                  <div
                    style={{
                      // width: 251,
                      height: 49,
                      width: "100%",
                    }}
                  >
                    <div>Emergency Contact Phone</div>
                    <input
                      disabled={!editable}
                      style={{
                        // width: 251,
                        width: "100%",
                        height: 31,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        margin: 10,
                        marginLeft: 0,
                        marginTop: 0,

                        backgroundColor: editable ? theme.primary : "#444",
                        fontSize: 13,
                        borderRadius: 10,
                        border: `1px solid grey`,
                      }}
                      onChange={(value) =>
                        setFormData({
                          ...formData,
                          emergencyContact: {
                            ...formData.emergencyContact,
                            phoneNumber: value.target.value,
                          },
                        })
                      }
                      value={formData.emergencyContact.phoneNumber}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <button
          style={{
            width: 176,
            height: 65,
            backgroundColor: theme.base,
            borderRadius: 15,
            border: "solid white 1px",
          }}
          onClick={onDiscard}
        >
          {editable ? "Discard" : "Back"}
        </button>
        {apiResonse?.status === 201 && (
          <div style={{ fontSize: 20, color: "green" }}>
            {"User created sucessfully"}
          </div>
        )}
        {apiResonse?.status !== 201 && apiResonse?.status !== -1 && (
          <div style={{ fontSize: 20, color: "red" }}>
            {"Failed to create user: " + apiResonse?.message}
          </div>
        )}
        {editable && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {formData?.isAdmin && (
              <div
                style={{
                  textAlign: "center",
                  marginRight: 15,
                }}
              >
                All Admin Users have the "Admin Role" by default.
              </div>
            )}
            {(perms.accessLevel === 8 ||
              perms.accessLevel === 9 ||
              perms.accessLevel === 13 ||
              perms.accessLevel === 12 ||
              perms.accessLevel === 11) && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: 49,
                  alignItems: "center",
                }}
              >
                {(perms.accessLevel === 8 ||
                  perms.accessLevel === 9) && (
                    <div>
                      <div style={{ marginRight: 10 }}>
                        {mode === "create"
                          ? "New user is a Maintenance Operator"
                          : "User is an maintenance operator"}
                      </div>
                      <input
                        type="checkbox"
                        style={{
                          height: 31,
                          marginRight: 30,
                        }}
                        disabled={formData.isAdmin}
                        onChange={(value) =>
                          setFormData({
                            ...formData,
                            isMaintenance: value.target.checked,
                          })
                        }
                        value={formData.isMaintenance}
                        checked={formData.isMaintenance}
                      />
                    </div>
                  )}
                <div style={{ marginRight: 10 }}>
                  {mode === "create"
                    ? "New user is an Admin"
                    : "User is an admin"}
                </div>
                <input
                  type="checkbox"
                  style={{
                    height: 31,
                    marginRight: 30,
                  }}
                  disabled={formData.isMaintenance}
                  onChange={(value) =>
                    setFormData({ ...formData, isAdmin: value.target.checked })
                  }
                  value={formData.isAdmin}
                  checked={formData.isAdmin}
                />
              </div>
            )}
            <button
              style={{
                width: 176,
                height: 65,
                backgroundColor: editable ? theme.primary : "#444",
                borderRadius: 15,
              }}
              onClick={onSubmit}
            >
              {mode === "create" ? "Create" : "Update"} User
            </button>
          </div>
        )}
      </div>
      {/* Information Popup */}
      <Popup
        open={modalOpen}
        onOpen={() => {
          setCopiedText("");
        }}
        onClose={() => setModalOpen(false)}
        closeOnDocumentClick
        closeOnEscape
        modal
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: theme.base,
            color: theme.fontColor,
            width: 405,
            minHeight: 165,
            borderRadius: 15,
            border: `1px solid white`,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              maxWidth: 390,
            }}
          >
            This link will be sent to the email provided for this user.
          </div>
          <div
            style={{
              maxWidth: 390,
            }}
          >
            Here is a copy for you in case that email fails to be received.
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: editable ? theme.primary : "#444",
              width: 390,
              borderRadius: 15,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                textWrap: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {apiResonse?.message}
            </div>
            <button
              style={{}}
              onClick={() => {
                navigator.clipboard.writeText(apiResonse?.message);
                setCopiedText("COPIED!");
              }}
            >
              <IconContext.Provider
                value={{
                  color: theme.fontColor,
                  size: 15,
                }}
              >
                <FaCopy />
              </IconContext.Provider>
            </button>
          </div>
          <div style={{ color: "green" }}>{copiedText}</div>
        </div>
      </Popup>
    </div>
  );
};

export default UserEditMenu;
