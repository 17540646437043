import React, { useContext, useEffect, useState } from "react";
import { ColorContext } from "App";
import { awareNodeStatus, getDeviceStatusCode } from "utils/iconUtils";

export default function NodeStatus({ device }) {
  const theme = useContext(ColorContext);

  const [status, setStatus] = useState();

  const RED = "#C73B1D";
  const YELLOW = "#D9AF27";
  const ORANGE = "#FF8918";
  const BLACK = "#000000";
  const WHITE = "#FFFFFF";
  const GREEN = "#01b574";
  const colors = {
    Offline: {
      background: BLACK,
      color: WHITE,
      border: "2px solid #ccc",
    },
    Slow: {
      background: YELLOW,
      color: BLACK,
      border: "",
    },
    Warning: {
      background: ORANGE,
      color: BLACK,
      border: "",
    },
    Maintenance: {
      background: ORANGE,
      color: WHITE,
      border: "",
    },
    Flooding: {
      background: RED,
      color: WHITE,
      border: "",
    },
    Online: {
      background: WHITE,
      color: BLACK,
      border: `2px solid ${GREEN}`,
    },
    Closed: {
      background: RED,
      color: WHITE,
      border: "",
    },
    Alerting: {
      background: RED,
      color: WHITE,
      border: "",
    },
    Open: {
      background: WHITE,
      color: BLACK,
      border: `2px solid ${GREEN}`,
    },
    Idle: {
      background: WHITE,
      color: BLACK,
      border: `2px solid ${GREEN}`,
    },
  };

  useEffect(() => setStatus(getDeviceStatusCode(device)), []);

  const statusColor = () => {
    return colors[status] || colors["Offline"];
  };

  if (status)
    return (
      <div
        style={{
          backgroundColor: statusColor().background,
          color: statusColor().color,
          border: statusColor().border,
          borderRadius: 8,
          padding: 5,
          width: 110,
          textAlign: "center",
          boxSizing: "border-box",
          height: 30,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {device.data ? status : "Offline"}
      </div>
    );
}
