import { ColorContext, DeviceContext } from "App";
import LocationSetter from "components/AlertCreator/components/LocationSetter";
import React, { useContext, useEffect, useState } from "react";
import { MapContainer, Marker, Polyline, TileLayer } from "react-leaflet";
import L from "leaflet";
import TextToggle from "components/UI/TextToggle";
import * as api from "apis/FusionAPI";
import { ConnectionsContext } from "../Connections";
import {
  getDynamicDeviceIcon,
  getRescAlertStatus,
  displayStatusOptions,
} from "utils/iconUtils";
import FullScreenMap from "components/FullScreenMap";
import ConfirmPopup from "components/UI/ConfirmPopup";
import { usePerms } from "hooks/usePerms";
import { FaAudioDescription, FaPlay } from "react-icons/fa";
import AudioPreview from "components/UI/AudioPreview";
import { selectClasses } from "@mui/material";
import { Page404 } from "pages/404";
import PageIndex from "components/UI/PageIndex";
import DeviceHistory from "./DeviceHistory";

export default function RescAlertDeviceSettings({
  nodeList,
  selectedDevice,
  setShowDeviceSettings,
  showDeviceSettings,
  setNodeList,
  widget = false,
  showHistory,
  setShowHistory,
  type,
  setCustomDataOverride,
  globalAction = false,
}) {
  const perms = usePerms();

  const theme = useContext(ColorContext);
  const deviceContext = useContext(DeviceContext);

  const device = nodeList[selectedDevice];
  const [alias, setAlias] = useState(device.alias);
  const [comment, setComment] = useState(device.comments);
  const [roadStatus, setRoadStatus] = useState(
    parseInt(device.data[0]?.status)
  );
  const [message, setMessage] = useState(
    "*You have unsaved changes, Discard or Save to continue*"
  );
  const [mapCenter, setMapCenter] = useState(device.location);
  const connectionsContext = useContext(ConnectionsContext);
  const [icon, setIcon] = useState(getDynamicDeviceIcon(device, "icon"));
  const [maintenanceOverride, setMaintenanceOverride] = useState(false);
  const [viewMaintenanceMessage, setViewMaintenanceMessage] = useState(false);

  const [killswitchState, setKillswitchState] = useState(device.kill_switch);
  const [selectedTone, setSelectedTone] = useState({
    id: 0,
  });
  useEffect(() => {
    if (device.data[0]?.status == -1) {
      if (roadStatus == -1) {
        return;
      }
    }
    setIcon(
      getDynamicDeviceIcon(
        {
          ...device,
          data: [
            {
              status: roadStatus ? 1 : 0,
            },
          ],
        },
        "icon"
      )
    );
    if (setCustomDataOverride)
      setCustomDataOverride({
        ...device,
        data: [
          {
            status: roadStatus ? 1 : 0,
          },
        ],
      });
  }, [roadStatus]);

  useEffect(() => {
    setComment(device.comments);
    setRoadStatus(parseInt(device.data[0]?.status));
    setKillswitchState(device.kill_switch);
    if (device.device_type == "Technology Bridge_Siren/Giant Voice") {
      const tone =
        deviceContext.deviceTypes[device.system_type][
          device.device_type.split("_")[0]
        ][device.device_type.split("_")[1]].sirenTypes[0];
      setSelectedTone(tone);
    }
  }, [selectedDevice]);

  if (
    !(
      device.device_type == "Manual Road Closure" ||
      device.device_type == "Technology Bridge_Flashing Lights" ||
      device.device_type == "Technology Bridge_Siren/Giant Voice"
    )
  )
    return null;
  const lines = device.coordinates;

  const hasDataChanged = () => {
    return comment !== "" || roadStatus !== parseInt(device.data[0]?.status)
      ? true
      : false;
  };

  const canOverrideMaintenance = () => {
    return perms.testPermission([9], []);
  };

  const setRoadStatusForSiren = (status) => {
    if (status == 1) {
      setRoadStatus(2);
      setSelectedTone(
        deviceContext.deviceTypes[device.system_type][
          device.device_type.split("_")[0]
        ][device.device_type.split("_")[1]].sirenTypes[0]
      );
    } else {
      setRoadStatus(status);
    }
  };

  const submitDeviceSettings = async (device_ = null) => {
    if (killswitchState) return;
    if (
      (device.device_type == "Technology Bridge_Siren/Giant Voice" ||
        device.device_type == "Technology Bridge_Flashing Lights") &&
      device.data[0]?.status == -1
    ) {
      if (!canOverrideMaintenance()) {
        setMessage(
          "You do not have permission to override Maintenance Mode. Please contact your administrator."
        );
        return;
      }
    }
    // api call to update device settings
    try {
      const res = await api.updateConnectedDevice(
        {
          ...device,
          alias,
          maintenanceOverride,
          comments: device_?.comment || comment,
          status: device_?.roadStatus || roadStatus,
        },
        true
      );

      if (res.status === 200) {
        await deviceContext.getDevices();
        setShowDeviceSettings(false);
        setComment("");
      } else {
        setMessage("Error updating device settings. Please try again.");
      }
    } catch {
      setMessage("Error updating device settings. Please try again.");
    }
  };

  const sirenSelector = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "30%",
          }}
        >
          Siren Tone:
        </div>
        <div
          style={{
            width: "70%",
            // padding: 5,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <select
              style={{
                background: theme.primary,
                height: 40,
                marginTop: 10,
                // marginBottom: 20,
                borderRadius: 10,
                paddingLeft: 15,
                width: "100%",
              }}
              value={selectedTone.id}
              onChange={(e) => {
                const tone = deviceContext.deviceTypes[device.system_type][
                  device.device_type.split("_")[0]
                ][device.device_type.split("_")[1]].sirenTypes.find(
                  (tone) => tone.id == e.target.value
                );
                setSelectedTone(tone);
                setRoadStatus(tone.relay_id);
              }}
            >
              {deviceContext.deviceTypes[device.system_type][
                device.device_type.split("_")[0]
              ][device.device_type.split("_")[1]]?.sirenTypes.map((tone) => {
                return (
                  <option key={tone.name} value={tone.id}>
                    {tone.displayName}
                  </option>
                );
              })}
            </select>
            <AudioPreview
              type="siren"
              tone={selectedTone}
              roadStatus={roadStatus}
              setRoadStatus={setRoadStatus}
            />
          </div>
        </div>
      </div>
    );
  };

  const maintenanceOverrideMessage = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
      }}
    >
      <h2>Warning: Maintenance Mode Override</h2>
      <div>
        Warning: Maintenance Mode Override You are about to override
        “Maintenance Mode” for a “RescAlert Devices - Technology Bridge
        (Siren/Giant Voice)” device. This action is restricted to Maintenance
        Admin who are actively working in the field. Setting this device to
        Alerting will immediately activate the siren at full volume, which can
        cause severe or permanent hearing loss (including deafness) to anyone
        nearby without proper double hearing protection. By proceeding, you
        confirm that you are a Maintenance Admin, you understand and accept
        these risks, and you acknowledge that it is safe to continue.
      </div>
    </div>
  );

  const maintenanceWarningMessage = (
    <div>
      IMPORTANT NOTICE:{" "}
      <span style={{ color: "red" }}>
        The tamper door is still open. Because you have overridden “Maintenance
        Mode”, you can now set this device to an Alerting/Idle status. Proceed
        with extreme caution and ensure the area is safe, as full-volume
        activation may occur.{" "}
      </span>
    </div>
  );

  const handleKillswitchFlip = async () => {
    const res = await api.killswitchDevice(device.id, !killswitchState);
    if (res.status == 200) {
      setKillswitchState(!killswitchState);

    } else {;
    }
  };
 if (globalAction) {
    return <div>{device.device_type == "Technology Bridge_Siren/Giant Voice" &&
      (roadStatus == 1 || roadStatus == 2 || roadStatus == 6) &&
      sirenSelector()}</div>
  }

  if (!widget)
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 999,
        }}
      >
        {viewMaintenanceMessage && (
          <ConfirmPopup
            open={viewMaintenanceMessage}
            setOpen={setViewMaintenanceMessage}
            message={maintenanceOverrideMessage}
            options={["Cancel", "Continue"]}
            callbacks={[
              () => {
                setViewMaintenanceMessage(false);
                setMaintenanceOverride(false);
              },
              () => {
                setViewMaintenanceMessage(false);
                setMaintenanceOverride(true);
                setRoadStatus(0);
              },
            ]}
            buttonStyles={[
              {
                backgroundColor: "red",
                color: "white",
              },
              {
                backgroundColor: "black",
                color: "white",
              },
            ]}
            style={{
              width: "50vw",
            }}
          />
        )}
        <div
          style={{
            backgroundColor: theme.base,
            padding: 30,
            maxHeight: "calc(100% - 60px)",
            maxWidth: "80%",
            overflowY: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <h1>
                {device.alias} Device Settings ({device.node_id}){" "}
              </h1>
            </div>
            <div
              style={{ cursor: "pointer", marginLeft: 10 }}
              onClick={() => setShowDeviceSettings(false)}
            >
              <h2>X</h2>
            </div>
          </div>
          {(
            device.device_type == "Technology Bridge_Flashing Lights") && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: 20,
                textAlign: "center",
              }}
            >
              * This Device's Toggle Switch is set to:{" "}
              {device.data[0]?.ToggleSwitch} *
            </div>
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "300px",
                  width: window.innerWidth / 3,
                }}
              >
                <FullScreenMap
                  key={"fullScreenMap" + icon.toString()}
                  mapCenter={mapCenter}
                  zoom={13}
                  style={{
                    borderRadius: 10,
                    height: "100%",
                    pointerEvents: "auto",
                  }}
                >
                  <LocationSetter center={mapCenter} zoom={12} />

                  <>
                    {lines.map((line, lineIndex) => (
                      <>
                        {line.points.length > 1 && (
                          <Polyline
                            key={lineIndex + "" + Date.now()}
                            positions={line.points}
                            color={
                              getRescAlertStatus({
                                ...device,
                                data: [
                                  {
                                    status: roadStatus,
                                  },
                                ],
                              }) === displayStatusOptions(device)[0]
                                ? "lime"
                                : "red"
                            }
                          />
                        )}
                        {line.points.map((point, pointIndex) => {
                          return (
                            <Marker
                              key={
                                lineIndex + "" + pointIndex + "" + Date.now()
                              }
                              position={point}
                              draggable={false}
                              icon={
                                pointIndex == 0 ||
                                pointIndex == line.points.length - 1
                                  ? icon
                                  : new L.DivIcon({
                                      className: "custom-x-icon",
                                      html: `<div>
                <div style="color: black; font-size: 30px; position: absolute; top: -4px; left: -2px;">X</div>
                <div style="color: ${
                  getRescAlertStatus({
                    ...device,
                    data: [
                      {
                        status: roadStatus,
                      },
                    ],
                  }) === displayStatusOptions(device)[0]
                    ? "lime"
                    : "red"
                }; font-size: 24px; position: absolute; top: 0px; left: 0px;">X</div>
                </div>`,
                                      iconSize: [20, 20],
                                      iconAnchor: [10, 16],
                                    })
                              }
                              interactive
                            ></Marker>
                          );
                        })}
                      </>
                    ))}
                  </>
                </FullScreenMap>
                {(device.device_type ===
                  "Technology Bridge_Siren/Giant Voice" ||
                  device.device_type === "Technology Bridge_Flashing Lights") &&
                  (perms.accessLevel === 8 ||
                    perms.accessLevel === 9 ||
                    perms.accessLevel === 13 ||
                    killswitchState) ? (
                    <div
                      style={{
                        padding: 10,
                        borderRadius: 5,
                        cursor: "pointer",
                        textAlign: "center",
                        backgroundColor: killswitchState
                          ? "orange"
                          : theme.primary,
                        color: killswitchState ? "black" : "white",
                        marginTop: 10,

                        // width: 80,
                      }}
                      onClick={() => {
                        if (
                          perms.accessLevel === 8 ||
                          perms.accessLevel === 9 ||
                          perms.accessLevel === 13
                        ) {
                          handleKillswitchFlip();
                        }
                      }}
                    >
                      {(perms.accessLevel === 8 ||
                        perms.accessLevel === 9 ||
                        perms.accessLevel === 13) &&
                        (killswitchState ? "Disable" : "Enable")}{" "}
                      Maintenance Mode
                    </div>
                  ) : <div></div>}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                opacity: killswitchState ? 0.25 : 1,
                cursor: killswitchState ? "not-allowed !important" : "auto",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: window.innerWidth / 3,
                  padding: 10,
                  gap: 20,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      width: "25%",
                    }}
                  >
                    Device Alias:
                  </div>
                  <div
                    style={{
                      width: "75%",
                    }}
                  >
                    {device.alias}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      width: "25%",
                    }}
                  >
                    Status:
                  </div>
                  <div
                    style={{
                      width: "75%",
                      display: "flex",
                      flexDirection: "column",
                      gap: 20,
                    }}
                  >
                    <TextToggle
                      value={roadStatus}
                      setValue={
                        device.device_type ===
                        "Technology Bridge_Siren/Giant Voice"
                          ? setRoadStatusForSiren
                          : setRoadStatus
                      }
                      options={displayStatusOptions(device)}
                      optionColorOn={!roadStatus ? "white" : "#C73B1D"}
                      optionTextColorOn={!roadStatus ? "black" : "white"}
                      optionBorder1="2px solid #01b574"
                      optionColorOff="red"
                      disabled={
                        device.data[0]?.status === -1 &&
                        (device.device_type ==
                          "Technology Bridge_Siren/Giant Voice" ||
                          device.device_type ==
                            "Technology Bridge_Flashing Lights") &&
                        !maintenanceOverride
                      }
                    />
                    {(device.device_type ==
                      "Technology Bridge_Siren/Giant Voice" ||
                      device.device_type ==
                        "Technology Bridge_Flashing Lights") &&
                      device.data[0]?.status == -1 &&
                      !canOverrideMaintenance() && (
                        <div>
                          This device is in{" "}
                          <span style={{ color: "orange" }}>
                            "Maintenance Mode"
                          </span>
                          . The Tamper Door must be closed before continuing.
                        </div>
                      )}
                    {(device.device_type ==
                      "Technology Bridge_Siren/Giant Voice" ||
                      device.device_type ==
                        "Technology Bridge_Flashing Lights") &&
                      device.data[0]?.status == -1 &&
                      canOverrideMaintenance() && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 10,
                            alignItems: "center",
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={maintenanceOverride}
                            onChange={() => {
                              if (maintenanceOverride) {
                                setMaintenanceOverride(false);
                              } else {
                                setViewMaintenanceMessage(true);
                              }
                            }}
                            disabled={killswitchState}
                          />
                          <span>
                            Override{" "}
                            <span style={{ color: "orange" }}>Maintenance</span>
                            ?
                          </span>
                        </div>
                      )}
                  </div>
                </div>
                {device.device_type == "Technology Bridge_Siren/Giant Voice" &&
                  (roadStatus == 1 || roadStatus == 2 || roadStatus == 6) &&
                  sirenSelector()}
                {maintenanceOverride && maintenanceWarningMessage}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    alignContent: "center",
                  }}
                >
                  <div
                    style={{
                      width: "25%",
                    }}
                  >
                    Comments:
                  </div>
                  <textarea
                    rows={4}
                    type="text"
                    value={comment}
                    style={{
                      border: "1px solid white",
                      width: "75%",
                      padding: 5,
                      resize: "none",
                    }}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder="(Optional)"
                    disabled={killswitchState}
                  />
                </div>
                {hasDataChanged() && (
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    {message}
                  </div>
                )}
              </div>
              {/* <div
                style={{
                  backgroundColor: theme.primary,
                  padding: 10,
                  borderRadius: 5,
                  cursor: "pointer",
                }}
                onClick={() => setShowDeviceSettings(false)}
              >
                View History
              </div> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: 20,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 10,
                  }}
                >
                  {device.device_type ===
                    "Technology Bridge_Siren/Giant Voice" && (
                    <div
                      style={{
                        padding: 10,
                        borderRadius: 5,
                        cursor: "pointer",
                        textAlign: "center",
                        backgroundColor: theme.primaryShadow,

                        // width: 80,
                      }}
                      onClick={() => {
                        if (killswitchState) return;
                        submitDeviceSettings({
                          ...device,
                          comment: "Cancel Audio",
                          roadStatus: 7,
                        });
                      }}
                    >
                      Cancel Audio
                    </div>
                  )}{" "}
                  {device.device_type ===
                    "Technology Bridge_Siren/Giant Voice" && (
                    <div
                      style={{
                        padding: 10,
                        borderRadius: 5,
                        cursor: "pointer",
                        textAlign: "center",
                        backgroundColor: theme.primaryShadow,

                        // width: 80,
                      }}
                      onClick={() => {
                        if (killswitchState) return;
                        submitDeviceSettings({
                          ...device,
                          comment: "Power Cycle Device",
                          roadStatus: 8,
                        });
                      }}
                    >
                      Power Cycle Device
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 10,
                  }}
                >
                  {hasDataChanged() && (
                    <div
                      style={{
                        border: "1px solid white",
                        padding: 10,
                        borderRadius: 5,
                        cursor: "pointer",
                        textAlign: "center",

                        width: 80,
                      }}
                      onClick={() => {
                        setShowDeviceSettings(false);
                        setComment("");
                        setRoadStatus(parseInt(device.data[0]?.status));
                      }}
                    >
                      Discard
                    </div>
                  )}
                  <div
                    style={{
                      backgroundColor: theme.primary,
                      padding: 10,
                      borderRadius: 5,
                      cursor: "pointer",
                      textAlign: "center",
                      width: 80,
                    }}
                    onClick={submitDeviceSettings}
                  >
                    Save
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DeviceHistory device={device} selectedDevice={selectedDevice} />
        </div>
      </div>
    );

  if (widget) {
    if (!showHistory)
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {viewMaintenanceMessage && (
            <ConfirmPopup
              open={viewMaintenanceMessage}
              setOpen={setViewMaintenanceMessage}
              message={maintenanceOverrideMessage}
              options={["Cancel", "Continue"]}
              callbacks={[
                () => {
                  setViewMaintenanceMessage(false);
                  setMaintenanceOverride(false);
                },
                () => {
                  setViewMaintenanceMessage(false);
                  setMaintenanceOverride(true);
                },
              ]}
              buttonStyles={[
                {
                  backgroundColor: "red",
                  color: "white",
                },
                {
                  backgroundColor: "black",
                  color: "white",
                },
              ]}
              style={{
                width: "50vw",
              }}
            />
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // width: window.innerWidth / 3,
              // padding: 10,
              gap: 10,
            }}
          >
            {(
              device.device_type == "Technology Bridge_Flashing Lights") && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                * This Device's Toggle Switch is set to:{" "}
                {device.data[0]?.ToggleSwitch} *
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "30%",
                }}
              >
                Device Alias:
              </div>
              <div
                style={{
                  width: "70%",
                  // padding: 5,
                }}
              >
                {device.alias}
              </div>
              {/* <input
                  type="text"
                  value={alias}
                  onChange={(e) => setAlias(e.target.value)}
                  style={{
                    border: "1px solid white",
                    width: "75%",
                    padding: 5,
                  }}
                /> */}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "30%",
                }}
              >
                Status:
              </div>
              <div
                style={{
                  width: "75%",
                  display: "flex",
                  gap: 20,
                  flexDirection: "column",
                }}
              >
                <TextToggle
                  value={roadStatus}
                  setValue={
                    device.device_type === "Technology Bridge_Siren/Giant Voice"
                      ? setRoadStatusForSiren
                      : setRoadStatus
                  }
                  options={displayStatusOptions(device)}
                  optionColorOn={!roadStatus ? "white" : "#C73B1D"}
                  optionTextColorOn={!roadStatus ? "black" : "white"}
                  optionBorder1="2px solid #01b574"
                  optionColorOff="red"
                  disabled={
                    device.data[0]?.status === -1 &&
                    (device.device_type ==
                      "Technology Bridge_Siren/Giant Voice" ||
                      device.device_type ==
                        "Technology Bridge_Flashing Lights") &&
                    !maintenanceOverride
                  }
                />
                {(device.device_type == "Technology Bridge_Siren/Giant Voice" ||
                  device.device_type == "Technology Bridge_Flashing Lights") &&
                  device.data[0]?.status == -1 &&
                  !canOverrideMaintenance() && (
                    <div>
                      This device is in{" "}
                      <span style={{ color: "orange" }}>
                        "Maintenance Mode"
                      </span>
                      . The Tamper Door must be closed before continuing.
                    </div>
                  )}
                {(device.device_type == "Technology Bridge_Siren/Giant Voice" ||
                  device.device_type == "Technology Bridge_Flashing Lights") &&
                  device.data[0]?.status == -1 &&
                  canOverrideMaintenance() && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 10,
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={maintenanceOverride}
                        onChange={() => {
                          if (maintenanceOverride) {
                            setMaintenanceOverride(false);
                          } else {
                            setViewMaintenanceMessage(true);
                          }
                        }}
                      />
                      <span>
                        Override{" "}
                        <span style={{ color: "orange" }}>
                          Maintenance Mode
                        </span>
                        ?
                      </span>
                    </div>
                  )}
              </div>
            </div>
            {device.device_type == "Technology Bridge_Siren/Giant Voice" &&
              (roadStatus == 1 || roadStatus == 2 || roadStatus == 6) &&
              sirenSelector()}
            {maintenanceOverride && maintenanceWarningMessage}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignContent: "center",
                // justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "30%",
                }}
              >
                Comments:
              </div>
              <textarea
                rows={4}
                type="text"
                value={comment}
                style={{
                  border: "1px solid white",
                  width: "70%",
                  padding: 5,
                  resize: "none",
                }}
                onChange={(e) => setComment(e.target.value)}
                placeholder="(Optional)"
              />
            </div>
            {/* {hasDataChanged() && (
            <div
              style={{
                width: "100%",
                textAlign: "center",
              }}
            >
              {message}
            </div>
          )} */}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              paddingLeft: 20,
              gap: 10,
              paddingTop: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
              }}
            >
              {device.device_type === "Technology Bridge_Siren/Giant Voice" && (
                <div
                  style={{
                    padding: 10,
                    borderRadius: 5,
                    cursor: "pointer",
                    textAlign: "center",
                    backgroundColor: theme.primaryShadow,

                    // width: 80,
                  }}
                  onClick={() => {
                    submitDeviceSettings({
                      ...device,
                      comment: "Cancel Audio",
                      roadStatus: 7,
                    });
                  }}
                >
                  Cancel Audio
                </div>
              )}{" "}
              {device.device_type === "Technology Bridge_Siren/Giant Voice" && (
                <div
                  style={{
                    padding: 10,
                    borderRadius: 5,
                    cursor: "pointer",
                    textAlign: "center",
                    backgroundColor: theme.primaryShadow,

                    // width: 80,
                  }}
                  onClick={() => {
                    submitDeviceSettings({
                      ...device,
                      comment: "Power Cycle Device",
                      roadStatus: 8,
                    });
                  }}
                >
                  Power Cycle Device
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
              }}
            >
              <div
                style={{
                  backgroundColor: theme.primary,
                  padding: 10,
                  width: 80,
                  borderRadius: 5,
                  cursor: "pointer",
                  textAlign: "center",
                }}
                onClick={() => setShowHistory(!showHistory)}
              >
                History
              </div>
              {hasDataChanged() && (
                <div
                  style={{
                    border: "1px solid white",
                    padding: 10,
                    borderRadius: 5,
                    cursor: "pointer",
                    textAlign: "center",

                    width: 80,
                  }}
                  onClick={() => {
                    setShowDeviceSettings(false);
                    setComment("");
                    setRoadStatus(parseInt(device.data[0]?.status));
                  }}
                >
                  Discard
                </div>
              )}
              <div
                style={{
                  backgroundColor: theme.primary,
                  padding: 10,
                  borderRadius: 5,
                  cursor: "pointer",
                  textAlign: "center",
                  width: 80,
                }}
                onClick={submitDeviceSettings}
              >
                Save
              </div>
            </div>
          </div>
        </div>
      );
    if (showHistory)
      return <div onClick={() => setShowHistory(false)}>hide</div>;
  }
}
