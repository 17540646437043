import { FaQuestion } from "react-icons/fa";
import AWARE_ICON from "assets/icons/AWAREICON.png";
import AWARE_NODE from "assets/aware.png";
import RESCALERT_DEVICE from "assets/Sidebar_Logo_Closed.png";
import SIREN_GIANT_VOICE from "assets/resc_placeholder.png";
import TECHNOLOGY_BRIDGE from "assets/resc_placeholder.png";
import MANUAL_ROAD_CLOSURE from "assets/manual_road_closure.png";
import { MdDeviceHub, MdDevices, MdGroupWork } from "react-icons/md";
import { RiBarChartGroupedLine, RiDeviceLine } from "react-icons/ri";
import {
  TbDeviceAirtag,
  TbDeviceAnalytics,
  TbDeviceCctv,
  TbDeviceDesktopAnalytics,
} from "react-icons/tb";
import { PiDevicesFill } from "react-icons/pi";
import { Fa42Group, FaFireFlameCurved } from "react-icons/fa6";

import L from "leaflet";

//AWARE Dynamic Icons
import aware from "assets/aware.png";
import aware_online_camera from "assets/awareicons/AWARE Flood - Status Online (no camera).png";
import aware_alarm_camera_cell from "assets/awareicons/AWARE Flood - Status Flooding (with camera).png";
import aware_alarm_camera_sat from "assets/awareicons/AWARE Flood - Status Flooding (with camera).png";
import aware_alarm_nocamera from "assets/awareicons/AWARE Flood - Status Flooding (no camera).png";
import aware_camera_cell from "assets/awareicons/AWARE Flood - Status Online (no camera).png";
import aware_camera_sat from "assets/awareicons/AWARE Flood - Status Online (no camera).png";
import aware_offline_camera_cell from "assets/awareicons/AWARE Flood - Status Offline (with camera).png";
import aware_offline_camera_sat from "assets/awareicons/AWARE Flood - Status Offline (with camera).png";
import aware_offline_nocamera from "assets/awareicons/AWARE Flood - Status Offline (no camera).png";
import aware_slow_camera_cell from "assets/awareicons/AWARE Flood - Status Slow (with camera).png";
import aware_slow_camera_sat from "assets/awareicons/AWARE Flood - Status Slow (with camera).png";
import aware_slow_nocamera from "assets/awareicons/AWARE Flood - Status Slow (no camera).png";
import aware_warning_camera_cell from "assets/awareicons/AWARE Flood - Status Warning (with camera).png";
import aware_warning_camera_sat from "assets/awareicons/AWARE Flood - Status Warning (with camera).png";
import aware_warning_nocamera from "assets/awareicons/AWARE Flood - Status Warning (no camera).png";
import aware_maintenance_camera from "assets/awareicons/AWARE Flood - Status Maintenance (with camera).png";
import aware_maintenance_nocamera from "assets/awareicons/AWARE Flood - Status Maintenance (no camera).png";

//RescAlert Dynamic Icons
import rescalert from "assets/resc_placeholder.png";
import rescalert_closure_closed from "assets/rescalerticons/RescAlert Devices Manual Road Closures - Status Closed.png";
import rescalert_closure_open from "assets/rescalerticons/RescAlert Devices Manual Road Closures - Status Open.png";
import rescalert_bridge_closed_nocamera from "assets/rescalerticons/RescAlert Devices Technology Bridge - Status Closed (no camera).png";
import rescalert_bridge_closed_camera from "assets/rescalerticons/RescAlert Devices Technology Bridge - Status Closed (with camera).png";
import rescalert_bridge_offline_nocamera from "assets/rescalerticons/RescAlert Devices Technology Bridge - Status Offline (no camera).png";
import rescalert_bridge_offline_camera from "assets/rescalerticons/RescAlert Devices Technology Bridge - Status Offline (with camera).png";
import rescalert_bridge_open_nocamera from "assets/rescalerticons/RescAlert Devices Technology Bridge - Status Open (no camera).png";
import rescalert_bridge_open_camera from "assets/rescalerticons/RescAlert Devices Technology Bridge - Status Open (with camera).png";
import rescalert_bridge_warning_nocamera from "assets/rescalerticons/RescAlert Devices Technology Bridge - Status Warning (no camera).png";
import rescalert_bridge_warning_camera from "assets/rescalerticons/RescAlert Devices Technology Bridge - Status Warning (with camera).png";
import rescalert_bridge_maintenance_nocamera from "assets/rescalerticons/RescAlert Devices Technology Bridge - Status Maintenance (no camera).png";
import rescalert_bridge_maintenance_camera from "assets/rescalerticons/RescAlert Devices Technology Bridge - Status Maintenance (with camera).png";

import rescalert_siren_alerting_nocamera from "assets/rescalerticons/RescAlert Devices Technology Bridge (Sirens-Giant Voice) Alerting.png";
import rescalert_siren_alerting_camera from "assets/rescalerticons/RescAlert Devices Technology Bridge (Sirens-Giant Voice) Alerting with camera.png";
import rescalert_siren_offline_nocamera from "assets/rescalerticons/RescAlert Devices Technology Bridge (Sirens-Giant Voice) Offline.png";
import rescalert_siren_offline_camera from "assets/rescalerticons/RescAlert Devices Technology Bridge (Sirens-Giant Voice) Offline with camera.png";
import rescalert_siren_idle_nocamera from "assets/rescalerticons/RescAlert Devices Technology Bridge (Sirens-Giant Voice) Idle.png";
import rescalert_siren_idle_camera from "assets/rescalerticons/RescAlert Devices Technology Bridge (Sirens-Giant Voice) Idle with camera.png";
import rescalert_siren_maintenance_nocamera from "assets/rescalerticons/RescAlert Devices Technology Bridge (Sirens-Giant Voice) Maintenance.png";
import rescalert_siren_maintenance_camera from "assets/rescalerticons/RescAlert Devices Technology Bridge (Sirens-Giant Voice) Maintenance with camera.png";

export const iconList = (size = 30) => [
  <MdDeviceHub size={size} />,
  <MdDevices size={size} />,
  <RiDeviceLine size={size} />,
  <TbDeviceAirtag size={size} />,
  <TbDeviceDesktopAnalytics size={size} />,
  <TbDeviceCctv size={size} />,
  <PiDevicesFill size={size} />,
  <TbDeviceAnalytics size={size} />,
  <Fa42Group size={size} />,
  <RiBarChartGroupedLine size={size} />,
  <MdGroupWork size={size} />,
  <FaFireFlameCurved size={size} />,
];

export const group_icon = (id, size = 30) => {
  const icons = iconList(size);
  return icons[id] || <FaQuestion size={size} />;
};

export const deviceImageList = {
  AWARE: AWARE_ICON,
  Flood: AWARE_NODE,
  "RescAlert Devices": RESCALERT_DEVICE,
  RESCALERT: RESCALERT_DEVICE,
  "Manual Road Closure": MANUAL_ROAD_CLOSURE,
  "Technology Bridge_Flashing Lights": TECHNOLOGY_BRIDGE,
  "Technology Bridge_Siren/Giant Voice": SIREN_GIANT_VOICE,
};

const iconSizes = [50, 50];

export const getDynamicDeviceIcon = (
  device,
  type = "img",
  size = iconSizes
) => {
  let icon = null;
  if (device?.system_type === "AWARE") {
    icon = getAwareImage(device);
  }
  if (device?.system_type === "RescAlert Devices") {
    icon = getRescAlertImage(device);
  }

  if (type === "icon") {
    icon = imageToIcon(icon, size);
  }
  return icon || <FaQuestion />;
};

export const imageToIcon = (image, size, anchor) => {
  return L.icon({
    iconUrl: image,
    iconSize: size,
  });
};

export function getDeviceStatusCode(device) {
  if (device.system_type === "AWARE") return awareNodeStatus(device);
  if (device.system_type === "RescAlert Devices")
    return getRescAlertStatus(device);
}

//AWARE DEVICE FUNCTIONS
function getAwareImage(device) {
  const currentTime = Date.now();
  let status = "Online";

  if (!device?.data?.length > 0) return aware_offline_nocamera;

  if (device?.data[0]) {
    device.data = device.data.sort((a, b) => b.ts - a.ts); //I cannot figure out where it's getting reverse ordered, so I have to do it here.

    status = awareNodeStatus(device);
  }

  if (status === "Offline") {
    return device?.data[0].gatewayType
      ? aware_offline_camera_cell
      : device?.data[0]?.pict
      ? aware_offline_camera_sat
      : aware_offline_nocamera;
  }

  if (status === "Slow") {
    return device?.data[0].gatewayType
      ? aware_slow_camera_cell
      : device?.data[0]?.pict
      ? aware_slow_camera_sat
      : aware_slow_nocamera;
  }

  if (status === "Warning") {
    return device?.data[0].gatewayType
      ? aware_warning_camera_cell
      : device?.data[0]?.pict
      ? aware_warning_camera_sat
      : aware_warning_nocamera;
  }

  if (status === "Flooding") {
    if (device?.data[0]?.pict) {
      return device?.data[0].gatewayType
        ? aware_alarm_camera_cell
        : aware_alarm_camera_sat;
    }
    return aware_alarm_nocamera;
  }
  
  if (status === "Maintenance") {
    if (device?.data[0]?.pict) {
      return device?.data[0].gatewayType 
        ? aware_maintenance_camera
        : aware_maintenance_nocamera;
    }
    return aware_alarm_nocamera;
  }

  if (device?.data[0]?.pict) {
    return device?.data[0].gatewayType ? aware_camera_cell : aware_camera_sat;
  }

  return aware_online_camera;
}

function awareNodeStatus(device) {
  if (device.reporting_mode === "Offline") return device.reporting_mode;

  const currentTime = Date.now();

  const info = device.data[0];
  if (!info) return "Offline";
  const settings = device.device_settings;

  const timeDifference = (currentTime - info.ts) / (1000 * 60); // convert to minutes

  let status = device.reporting_mode;
  if (
    info.ffi1 ||
    info.drInd ||
    info.depthInd ||
    info.depth1 >= settings.depthDet3
  ) {
    status = "Flooding";
  } else if (
    info.depth1 >= settings.depthDet1 ||
    info.depth1 >= settings.depthDet2
  ) {
    status = "Warning";
  }

  return status;
}

//RESCALERT DEVICE FUNCTIONS

function getRescAlertImage(device) {
  const info = device?.data[0];
  const status = getRescAlertStatus(device);

  if (device.device_type === "Manual Road Closure") {
    return device?.data && info.status
      ? rescalert_closure_closed
      : rescalert_closure_open;
  }

  if (device.device_type === "Technology Bridge_Flashing Lights") {
    if (status === "Warning") {
      return info.pict
        ? rescalert_bridge_warning_camera
        : rescalert_bridge_warning_nocamera;
    }
    if (status === "Maintenance") {
      return info.pict
        ? rescalert_bridge_maintenance_camera
        : rescalert_bridge_maintenance_nocamera;
    }

    if (status === "Closed") {
      return info.pict
        ? rescalert_bridge_closed_camera
        : rescalert_bridge_closed_nocamera;
    }

    if (status === "Offline") {
      return info.pict
        ? rescalert_bridge_offline_camera
        : rescalert_bridge_offline_nocamera;
    }

    if (status === "Open") {
      return info.pict
        ? rescalert_bridge_open_camera
        : rescalert_bridge_open_nocamera;
    }

    return rescalert;
  }

  if (device.device_type === "Technology Bridge_Siren/Giant Voice") {
    if (status === "Maintenance") {
      return info.pict
        ? rescalert_siren_maintenance_camera
        : rescalert_siren_maintenance_nocamera;
    }

    if (status === "Alerting") {
      return info.pict
        ? rescalert_siren_alerting_camera
        : rescalert_siren_alerting_nocamera;
    }

    if (status === "Offline") {
      return info.pict
        ? rescalert_siren_offline_camera
        : rescalert_siren_offline_nocamera;
    }

    if (status === "Idle") {
      return info.pict
        ? rescalert_siren_idle_camera
        : rescalert_siren_idle_nocamera;
    }

    return rescalert;
  }
}

export function displayStatusOptions(device) {
  if (
    device?.device_type == "Technology Bridge_Siren/Giant Voice" ||
    device?.deviceType ==
      "RescAlert Devices Technology Bridge_Siren/Giant Voice"
  ) {
    return {
      0: "Idle",
      1: "Alerting",
      "-1": "Maintenance",
    };
  }
  return {
    0: "Open",
    1: "Closed",
    "-1": "Warning",
  };
}

export function getRescAlertStatus(device) {
  const info = device?.data[0];
  if (!info) return "Offline";
  let status = null;
  if (device.device_type === "Manual Road Closure") {
    status = info.status ? "Closed" : "Open";
  }

  if (device.device_type === "Technology Bridge_Flashing Lights") {
    if (info.status === -1) {
      return "Maintenance";
    }
    if (info.status === 0) {
      return "Open";
    }
    if (info.status === 1) {
      return "Closed";
    }
    return "Offline";
    // const currentTime = Date.now();
    // const timeDifference = (currentTime - info.ts) / (1000 * 60); // convert to minutes
    // if (timeDifference > 70) {
    //   status = "Offline";
    // }
    // status = "Closed";
    // status = "Warning";
    // status = "Offline";
    // status = "Open";
  }
  if (device.device_type === "Technology Bridge_Siren/Giant Voice") {
    // return "Offline";
    // return "Idle";
    // return "Alerting";
    if (info.status === -1) {
      return "Maintenance";
    }
    if (info.status === 0) {
      return "Idle";
    }
    if (info.status === 1) {
      return "Alerting";
    }
    return "Offline";
  }
  return status;
}
