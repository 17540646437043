import React, { useContext, useEffect, useState } from "react";

import * as api from "../../apis/FusionAPI";
import Popup from "reactjs-popup";

import { FaCopy } from "react-icons/fa6";
import { IconContext } from "react-icons";
import { useNavigate } from "react-router-dom";
import { usePerms } from "hooks/usePerms";
import RoleInput from "./RoleInput";
import UserEditMenu from "./UserEditMenu";
import { ColorContext } from "App";

export default function CreateUser() {
  const [checks, setChecks] = useState({
    role: true,
    base: true,
    department: true,
    rank: true,
    name: true,
    email: true,
    roles: true,
  });

  const [formData, setFormData] = useState({
    role: 0,
    base: "",
    department: "",
    rank: "",
    name: "",
    email: "",
    employeeId: "",
    phone: "",
    gender: "Not Specified",
    birthday: "",
    address: {
      street: "",
      city: "",
      state: "",
      zipcode: "",
    },
    emergencyContact: {
      name: "",
      phone: "",
    },
    isAdmin: false,
    isMaintenance: false,
    roles: {},
    ms_roles: {},
  });

  const [apiResonse, setApiResponse] = useState({ status: -1 });
  const [roleList, setRoleList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [copiedText, setCopiedText] = useState("");
  const perms = usePerms();
  const navigate = useNavigate();

  const theme = useContext(ColorContext);

  useEffect(() => {
    (async () => {
      if (!(await perms.validatePermissions(["Create-EditUsers"]))) {
        navigate("/404");
        return;
      }
    })();

    api.getAllRoles().then((result) => {
      setRoleList(result.data.result);
    });
  }, []);

  const onSubmit = async () => {
    const newChecks = { ...checks };
    newChecks.role = formData.role > -1;
    newChecks.base = formData.base !== "";
    newChecks.department = formData.department !== "";
    newChecks.rank = formData.rank !== "";
    newChecks.name = formData.name !== "";
    newChecks.email = formData.email !== "";
    let roleCheck = false;
    for (let role in formData.roles) {
      if (formData.roles[role] == true) {
        roleCheck = true;
        break;
      }
    }
    newChecks.roles = roleCheck;

    if (formData.isAdmin) newChecks.roles = true;

    setChecks({ ...newChecks });

    if (
      newChecks.base &&
      newChecks.department &&
      newChecks.rank &&
      newChecks.name &&
      newChecks.email 
      // && newChecks.roles
    ) {
      //make api call
      const temp = {
        ...formData,
        address: JSON.stringify(formData.address),
        emergencyContact: JSON.stringify(formData.emergencyContact),
      };

      let roleids = [];
      for (let role in temp.roles) {
        if (temp.roles[role]) {
          roleids.push(
            roleList.find((item) => item.role_name === role).role_Id
          );
        }
      }

      temp.roles = roleids;

      api
        .createFusionUser(
          temp.roles,
          temp.base,
          temp.department,
          temp.rank,
          temp.name,
          temp.email,
          temp.employeeId,
          temp.phoneNumber,
          temp.gender,
          temp.birthday,
          temp.address,
          temp.emergencyContact,
          temp.isAdmin,
          temp.isMaintenance
        )
        .then((result) => {
          if (result.data.message === "User creation successful") {
            setApiResponse({ status: 201, message: result.data.Link });
            setModalOpen(true);
          }
        })
        .catch((e) => {
          setApiResponse({ status: 500, message: e.response.data.message });
        });
    }
  };

  const onDiscard = () => {
    setFormData({
      role: "",
      base: "",
      department: "",
      rank: "",
      name: "",
      email: "",
      employeeId: "",
      phoneNumber: "",
      gender: "",
      birthday: "",
      address: {
        streetAddress: "",
        city: "",
        state: "",
        zipcode: "",
      },
      emergencyContact: {
        name: "",
        phoneNumber: "",
      },
      isAdmin: false,
      isMaintenance: false,
      roles: {},
      ms_roles: {},
    });

    setApiResponse({ status: -1 });
  };

  return (
    <UserEditMenu
      checks={checks}
      setChecks={setChecks}
      formData={formData}
      setFormData={setFormData}
      roleList={roleList}
      apiResonse={apiResonse}
      setApiResponse={setApiResponse}
      onSubmit={onSubmit}
      onDiscard={onDiscard}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      copiedText={copiedText}
      setCopiedText={setCopiedText}
      perms={perms}
      mode="create"
      lockedRolesList={[]}
    />
  );
}
